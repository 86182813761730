<template class="w-full">
  <div class="w-full">
    <Loader v-if="loading"
      class="w-full lg:w-main-container lg:h-sidebar"
      :class="getHeightOfScreenWidth(1)"
    />
    <div v-else class="grid grid-cols-2 gap-4 w-full">
      <template v-if="currentCategory &&
      (
        currentCategory.slug === 'news'
        || $route.query.search
        || currentCategory.slug === 'faq'
        || currentCategory.slug === 'hints'
      )
      && postsData.length > 0"
    >
        <div class="lg:rounded-lg overflow-hidden row-span-2 col-span-2"
          :class="{'lg:row-span-1 lg:col-span-1':postsData.length>1}"
          v-for="(post, index) in postsData" :key="post.id"
        >
          <div class="border-t border-b border-grayed lg:border lg:rounded-lg overflow-hidden">
            <PostCard @post-clicked="showPostModal" :small="postsData.length===1 &&!isMobile?false:true" :post-data="post" />
          </div>
        </div>
      </template>
      <template v-else-if="currentCategory && (
					currentCategory.slug === 'rules'
        || currentCategory.slug === 'privacy'
        || currentCategory.slug === 'contact'
        )
      ">
        <div
          class="overflow-hidden col-span-2 border-t border-b border-grayed lg:border "
          :class="{'rounded-lg':!isMobile}"
          v-if="postsData[0]"
        >
          <PostShow :reduced-picture-offset="true" :slug="postsData[0].slug"/>
        </div>
      </template>
      <template v-else>
	      <div class="rounded-lg overflow-hidden col-span-2 border-t border-b border-grayed lg:border"
          :class="{'rounded-lg':!isMobile}"
        >
	        <PostCard empty-post :post-data="{
            image: emptyPost.image,
            title: emptyPost.title,
						}"
	        />
	      </div>
      </template>
    </div>
    <div v-if="postsData.length > 0 && pagination.total > 6"
      class="mt-3" :class="{'hidden': loading}"
    >
      <div class="flex-1 flex items-center justify-end">
        <t-pagination
          variant="posts"
          :total-items="pagination.total"
          :per-page="pagination.perPage"
          :limit="6"
          v-model="currentPage"
          @change="next"
          :disabled="loadingPosts"
        />
      </div>
    </div>
	  <Modal hide-footer variant="post" v-if="isShowPostModal" :show-modal="isShowPostModal" @clicked="isShowPostModal = false">
		  <template #content>
			  <PostShow :slug="chosenPostSlug" />
		  </template>
	  </Modal>
  </div>
</template>

<script>
import PostCard from "@/views/content/posts/components/PostCard";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {ref} from "@vue/composition-api";
import Pagination from "@/views/content/components/Pagination";
import Loader from "@/views/Loader";
import {capitalize} from 'lodash';
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import {getHeightOfScreenWidth} from "@/common/helpers/utils";
import PostShow from "@/views/content/posts/PostShow.vue";
import Modal from "@/utils/modal/Modal.vue";
import router from "@/router";


export default {
  name: "Posts",
  components: {Modal, PostShow, Loader, Pagination, PostCard},
  data() {
    return {
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      isMobile: checkIsMobileDevice(),
      postCategory: null,
      capitalize: capitalize,
	    isShowPostModal: false,
	    chosenPostSlug: null,
    }
  },
  computed: {
    ...mapGetters('posts', ['postsData', 'pagination', 'loadingPosts', 'postsLoaded', 'searchQuery', 'emptyPost', 'lastPage', 'currentOnlyPostSlug', 'currentCategory']),
    ...mapGetters('loader', ['loading']),
	  ...mapGetters('lang', ['configData']),
  },
  watch: {
    '$route': {
      handler: function(val, oldVal) {
				if(val) {
					let postCategory = val.params.postCategory;
					let page = val.query.page ?? 1;
					let search = val.query.search;
					let slug = val.params.slug;
					if(search === undefined) {
						if(slug === undefined && !this.chosenPostSlug) {
							this.postCategory = postCategory;
							this.currentPage = Number(page);

							this.setCurrentCategory(this.postCategory);
							this.setLastPage(this.currentPage);
							this.fetchPostsRequest({postCategory: this.postCategory, page: page});
						}
					} else {
						this.currentPage = Number(page);
						this.fetchPostsRequest({search: this.search, page: page});
					}
				}
      },
      deep: true,
      immediate: true,
    },
	  '$route.query.search': {
      handler(val) {
				if(val) {
					this.currentPage = this.lastPage ? this.lastPage : 1;
					this.fetchPostsRequest({page: this.currentPage, search: val});
				}
      }
    },
	  isShowPostModal: {
		  handler(val) {
			  if (!val) {
					this.$nextTick(() => {
						this.chosenPostSlug = null;
					});
				  this.$router.push({
					  name: 'posts',
					  params: {postCategory: this.$route.params.postCategory},
					  query: {page: this.currentPage, search: this.$route.query.search}
				  });
			  }
		  }
	  }
  },
  methods: {
    ...mapActions('posts', ['fetchPostsRequest', 'fetchEmptyPostRequest']),
    ...mapMutations('posts', ['setSearchQuery', 'setLastPage', 'setCurrentCategory', 'setCurrentOnlyPostSlug', 'setPostsData']),
    next(val) {
      this.currentPage = Number(val);
			this.$router.push({name: 'posts', params: {postCategory: this.$route.params.postCategory}, query: {page: this.currentPage, search: this.$route.query.search}})
    },
	  showPostModal(slug) {
			this.isShowPostModal = true;
			this.chosenPostSlug = slug;
		  this.$router.push({name: 'posts', params: {postCategory: this.$route.params.postCategory, slug: slug}, query: {page: this.currentPage, search: this.$route.query.search}})
	  }
  },
  mounted() {
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;

	  if(!this.$route.params.postCategory && !this.$route.query.search) {
		  this.$router.push({name: 'posts', params: {postCategory: 'news'}, query: {page: this.currentPage}})
	  }
		if(this.$route.params.postCategory && this.$route.params.slug) {
			this.fetchPostsRequest({postCategory: this.$route.params.postCategory, page: this.currentPage});
			this.showPostModal(this.$route.params.slug)
		}
		if(this.$route.params.postCategory && !this.$route.params.slug) {
			this.fetchPostsRequest({postCategory: this.$route.params.postCategory, page: this.currentPage});
		}
		if(this.lastPage !== 1) {
			this.currentPage = this.lastPage;
		}
  },
	beforeDestroy() {
		this.setCurrentCategory(null);
		this.setPostsData([]);
	},
	setup() {
    const currentPage = ref(1)

    return { currentPage }
  },
}
</script>
